import { useSelector } from 'react-redux'
import moment from "moment-timezone";

import { ColumnInstruction } from "components/Table/HorizontalTable";
import { ItemProps } from "./instructions";
import { RootState } from 'model/store';
import { useCallback, useMemo, useState } from 'react';
import { getPositionById } from 'model/selectors/businessSettings';
import { generateWeekRangeForSelectedDate } from 'views/manager/dailyLogs/dailyLogsFunctions';

const columns: ColumnInstruction<ItemProps>[] = [
  { type: "projection", header: "Status", attribute: "status" },
  { type: "data", header: "Role", attribute: "role" },
  { type: "data", header: "Employee", attribute: "senderFullName" },
  { type: "data", header: "Shift Date and Time", attribute: "sendingShiftDate" },
  { type: "data", header: "Employee", attribute: "receiverFullName" },
  { type: "data", header: "Shift Date and Time", attribute: "receivingShiftDate" },
  { type: "data", header: "Reason", attribute: "reason" },

];



const tradeequestStatusTitle = (status: ScheduleEventStatus) => {
  switch (status) {
    case "approved":
      return "Approved";
    case "denied":
      return "Denied";
    case "pending":
      return "Pending";
    default:
      return "Pending";
  }
};
const useTradeRequests = () => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const publishedSchedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.schedules
  );

  const scheduleEvents: ScheduleEvent[] = useSelector(
    (state: RootState) => state.scheduleEvents
  );

  const [requestsStatusFilter, setRequestsStatusFilter] = useState<
    "pending" | "reviewed"
  >("pending");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const weekRange = useMemo(() => {
    return generateWeekRangeForSelectedDate(business, selectedDate);
  }, [business, selectedDate]);

  const onCalendarDayPress = useCallback(
    (d: Date) => {
      setSelectedDate(d);
    },
    [setSelectedDate]
  );

  const sortBySendingShiftDate = useCallback((a: ScheduleEvent, b: ScheduleEvent) => {
    const now = moment().unix()
    const sendingScheduleIdA = a.sendingScheduleId
    const sendingShiftIdA = a.sendingShiftId
    const sendingScheduleA = publishedSchedules.find(sch => sch.id === sendingScheduleIdA)
    if (!sendingScheduleA) {
      return 0
    }
    const sendingShiftA = sendingScheduleA.shifts.find(sh => sh.id === sendingShiftIdA)
    if (!sendingShiftA) {
      return 0
    }
    const sendingScheduleIdB = b.sendingScheduleId
    const sendingShiftIdB = b.sendingShiftId
    const sendingScheduleB = publishedSchedules.find(sch => sch.id === sendingScheduleIdB)
    if (!sendingScheduleB) {
      return 0
    }
    const sendingShiftB = sendingScheduleB.shifts.find(sh => sh.id === sendingShiftIdB)
    if (!sendingShiftA) {
      return 0
    }
    if (!sendingShiftB) {
      return 0
    }
    const aDate = moment(sendingShiftA.startDate.toDate())
    const bDate = moment(sendingShiftB.startDate.toDate())
    return Math.abs(aDate.unix() - now) - Math.abs(bDate.unix() - now);

  }, [publishedSchedules])

  const tradeRequests = useMemo(() => {
    if (requestsStatusFilter === "pending") {
      return scheduleEvents.filter(
        (tr) => tr.eventType === "trade" && tr.status === "pending"
      ).sort(sortBySendingShiftDate)
    }
    return scheduleEvents.filter(
      (tr) => tr.eventType === "trade" && (tr.status === "approved" || tr.status === "denied")
    ).sort(sortBySendingShiftDate)
  }, [scheduleEvents, requestsStatusFilter, sortBySendingShiftDate]);

  const transformRequestToTableFormat = useCallback(
    (to: ScheduleEvent): ItemProps | null => {
      const statusTitle = tradeequestStatusTitle(to.status);
      const senderStaff = fellowStaffMembers.find(
        (sm) => sm?.id === to.senderStaff?.id
      );
      const receiverStaff = fellowStaffMembers.find(
        (sm) => sm?.id === to.receiverStaff?.id
      );
      if (!senderStaff || !receiverStaff) return null;

      const senderFullName = `${senderStaff.contact.firstName} ${senderStaff.contact.lastName}`;
      const receiverFullName = `${receiverStaff.contact.firstName} ${receiverStaff.contact.lastName}`;
      const sendingScheduleId = to.sendingScheduleId
      const sendingShiftId = to.sendingShiftId
      const receivingScheduleId = to.receivingScheduleId
      const receivingShiftId = to.receivingShiftId
      const sendingSchedule = publishedSchedules.find(sch => sch.id === sendingScheduleId)
      const receivingSchedule = publishedSchedules.find(sch => sch.id === receivingScheduleId)
      if (!sendingSchedule || !receivingSchedule) return null
      const sendingShift = sendingSchedule.shifts.find(sh => sh.id === sendingShiftId)
      const receivingShift = receivingSchedule.shifts.find(sh => sh.id === receivingShiftId)
      if (!sendingShift || !receivingShift) return null
      const sendingShiftDate = sendingShift.startDate.toDate()
      console.log("sendingShiftDate", sendingShiftDate)
      const receivingShiftDate = receivingShift.startDate.toDate()
      console.log("receivingShiftDate", receivingShiftDate)
      return {
        uniqueId: to.id,
        status: statusTitle,
        senderFullName,
        receiverFullName,
        sendingShiftDate,
        receivingShiftDate,
        role: getPositionById(businessSettings, sendingShift.position)?.title ?? "",
        reason: to.description ?? "",
        fullScheduleEvent: to
      };
    },
    [fellowStaffMembers, publishedSchedules, businessSettings]
  );

  const itemsData = useMemo(() => {
    return tradeRequests.map(transformRequestToTableFormat).filter(x => !!x) as ItemProps[]
  }, [tradeRequests, transformRequestToTableFormat])

  console.log("itemsData", itemsData)

  return {
    columns,
    itemsData,
    onCalendarDayPress,
    weekRange,
    requestsStatusFilter, setRequestsStatusFilter
  }
}
export default useTradeRequests;